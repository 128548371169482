import React, { useState, useEffect } from "react";
import {
  Input,
  InputPrefix,
  TextBox,
  TextArea,
} from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { DropDownList, List } from "@progress/kendo-react-dropdowns";
import {
  chevronDownIcon,
  downloadIcon,
  eyeIcon,
} from "@progress/kendo-svg-icons";
import "../css/EditInfo.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import { Error } from "@progress/kendo-react-labels";
import axios from "axios";
import { Loader, LoaderType } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import moment from "moment";
import fileDownload from "js-file-download";

// Convert selected time format
const convertTimeFormat = (currentTime: any) => {
  const localDate1 = new Date(currentTime);

  // Get the local date-time components
  const localYear = localDate1.getFullYear();
  const localMonth = String(localDate1.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const localDay = String(localDate1.getDate()).padStart(2, "0");
  const localHours = String(localDate1.getHours()).padStart(2, "0");
  const localMinutes = String(localDate1.getMinutes()).padStart(2, "0");
  const localSeconds = String(localDate1.getSeconds()).padStart(2, "0");

  // Construct the local date-time string in your desired format
  const localDateTime = `${localYear}-${localMonth}-${localDay} ${localHours}:${localMinutes}:${localSeconds}`;

  // console.log("Outputs the local date-time format", localDateTime); // Outputs the local date-time format

  // Local time string
  // const localDateTimeString = "2024-08-16 13:30:00";

  // Convert the local time string to a Date object
  const [datePart, timePart] = localDateTime.split(" ");
  const [year, month, day] = datePart.split("-").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);

  // Create a new Date object in the local timezone
  const localDate = new Date(year, month - 1, day, hours, minutes, seconds);

  // Convert the local Date object to an ISO string (UTC)
  const globalDateTime = localDate.toISOString();

  // console.log("Outputs1:", globalDateTime); // Outputs: 2024-08-16T08:00:00.000Z

  // Create a Date object from the ISO string
  const date = new Date(globalDateTime);

  // Extract the hours, minutes, and seconds
  const hours1 = String(date.getUTCHours()).padStart(2, "0");
  const minutes1 = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds1 = String(date.getUTCSeconds()).padStart(2, "0");

  // Combine them into a time string
  const timeOnly = `${hours1}:${minutes1}`;

  // console.log("Outputs2:", timeOnly); // Outputs: 08:00:00
  return timeOnly;
};

const bookingDateTimeFormate = (value: any) => {
  // Create a Date object from the server date-time string
  // console.log("Create a Date object", currentTime);
  const localDate1 = new Date(value);

  // Get the local date-time components
  const localYear = localDate1.getFullYear();
  const localMonth = String(localDate1.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const localDay = String(localDate1.getDate()).padStart(2, "0");
  const localHours = String(localDate1.getHours()).padStart(2, "0");
  const localMinutes = String(localDate1.getMinutes()).padStart(2, "0");
  const localSeconds = String(localDate1.getSeconds()).padStart(2, "0");

  // Construct the local date-time string in your desired format
  const localDateTime = `${localYear}-${localMonth}-${localDay} ${localHours}:${localMinutes}:${localSeconds}`;

  // console.log("Outputs the local date-time format", localDateTime); // Outputs the local date-time format

  // Local time string
  // const localDateTimeString = "2024-08-16 13:30:00";

  // Convert the local time string to a Date object
  const [datePart, timePart] = localDateTime.split(" ");
  const [year, month, day] = datePart.split("-").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);

  // Create a new Date object in the local timezone
  const localDate = new Date(year, month - 1, day, hours, minutes, seconds);

  // Convert the local Date object to an ISO string (UTC)
  const globalDateTime = localDate.toISOString();
  return globalDateTime;
};
function convertTZ(date: string, tzString: string) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}

export const BookingForm = () => {
  const navigate = useNavigate();
  const { state: { infoId, view } = {} } = useLocation();
  const [views, setViews] = useState(view);
  console.log(infoId, "<< this data D");
  var documents;
  const [editFormData, setEditFormData] = useState(infoId);
  const formatMomentTime = (dateStr: string, dateTimeFormat: string) => {
    console.log("Date Time->", dateStr);
    return moment(dateStr).format(dateTimeFormat);
  };
  const [formData, setFormData] = useState({
    bookingid: infoId.BookingId,
    roomtype: infoId.RoomTypeId === 1 ? "Single Seater" : "Lounge",
    noofseats: infoId.NoOfSeats,
    customerid: infoId.CustomerId,
    customername: infoId.CustomerName,
    customertype: infoId.CustomerType,
    companyname: infoId.CompanyName,
    contactnumber: infoId.ContactNo,
    email: infoId.Email,
    address: infoId.Address,
    gst: infoId.GSTNumber,
    notes: infoId.Notes,
    fromTime:
      infoId.DurationId === 1
        ? formatMomentTime(infoId.BookingFrom, "HH:mm")
        : "",
    toTime:
      infoId.DurationId === 1
        ? formatMomentTime(infoId.BookingTo, "HH:mm")
        : "",
    duration: infoId.DurationId,
    bookingstatus: infoId.BookingStatus,
    package: infoId.PackageSelection,
    bookeddate: convertTimeFormat(editFormData.BookingDatetime),
    paymentstatus: infoId.PaymentStatus === 1 ? "Pending" : "Paid",
    roomcost: infoId.TotalAmount,
    refund: "0",
  });
  const [particularPackageData, setParticularPackageData] = useState("");

  const [rawStartMessage, setRawStartMessage] = useState("");
  const [rawEndMessage, setRawEndMessage] = useState("");
  const [upsStartMessage, setUpsStartMessage] = useState("");
  const [upsEndMessage, setUpsEndMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [kycDocuments, setKycDocuments] = useState({});
  const customerTypes = ["Company", "Individual"];
  const [rawCheck, setRawCheck] = useState(false);
  const [upsCheck, setUpsCheck] = useState(false);
  const [durationList, setDurationList] = useState([]);
  const [spaceList, setSpaceList] = useState([]);
  const [kycList, setKycList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [bookinTypeList, setBookinTypeList] = useState([]);
  const [check, setCheck] = useState(false);
  const customerValidationMessage = "Please choose the Customer Type";
  const roomValidationMessage = "Please choose the Room Type";
  const fromValidationMessage = "Please choose the From time";
  const toValidationMessage = "Please choose the To time";
  const durationValidationMessage = "Please choose the duration";
  const statusValidationMessage = "Please choose the Status";
  const packageValidationMessage = "Please choose the Package";
  const paymentValidationMessage = "Please choose the Payment Status";
  const contactValidationMessage = "Please enter the Valid Mobile Number";
  const bookedValidationMessage = "Please enter the Booked Date";
  const advanceValidationMessage = "Please enter the Advance Amount";
  const costValidationMessage = "Please enter the Cost of Room";
  const refundValidationMessage = "Please enter the Refund Amount";
  const seatsValidationMessage = "Please enter the No of Seats";
  const notesValidationMessage = "Please enter the Notes";
  const rawStartValidationMessage = "Raw Power Start Unit is empty";
  const rawendValidationMessage = "Must be Greater than Raw Power Start Unit";
  const upsStartValidationMessage = "UPS Power Start Unit is empty";
  const upsendValidationMessage = "Must be Greater than UPS Power Start Unit";
  const KycValidationMessage = "Please choose the KYC Documents";
  const handleChange = (e: any) => {
    // setToTimeFeild(e.target.value);

    const { name, value } = e.target;
    // console.log("Sample ", e);
    if (
      name === "ContactNo" ||
      name === "Deposit" ||
      name === "roomcost" ||
      name === "Refund"
    ) {
      if (/^\d*$/.test(value)) {
        setEditFormData({ ...editFormData, [name]: value });
      }
    } else {
      if (editFormData.CompanyName === "") {
        setcompanyNameMessage("Company name is required");
      }
      setEditFormData({ ...editFormData, [name]: value });
    }
  };

  const formatFileName = (fileName: string, fileType: string) => {
    const baseName = fileName + "." + fileType;
    return `${baseName}`;
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [selectedSpaceId, setSelectedSpaceId] = useState(editFormData.spaceId);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedKyc, setSelectedKyc] = useState(editFormData.kycDocumentId);
  // console.log("from time", formData.fromTime);
  const [fromTimeFeild, setFromTimeFeild] = useState(formData.fromTime);
  const [toTimeFeild, setToTimeFeild] = useState(formData.toTime);

  const spaceFeildValue =
    selectedSpace !== null ? selectedSpace["SpaceType"] : "";
  // console.log("qwertyuiop", selectedPackage);
  const packageFeildValue =
    selectedPackage !== null ? selectedPackage["PackageName"] : "";
  const DurationFeildValue =
    selectedDuration !== null ? selectedDuration["DurationName"] : "";
  const StartTimeFeildValue =
    editFormData.BookingFrom !== null
      ? convertTimeFormat(editFormData.BookingFrom)
      : "";
  const EndTimeFeildValue =
    editFormData.BookingTo !== null
      ? convertTimeFormat(editFormData.BookingTo)
      : "";
  const CustomeTypeFeildValue = infoId !== null ? infoId.CustomerType : "";
  const KYCTypeFeildValue = selectedKyc !== null ? selectedKyc["Name"] : "";
  const handletimechange = (e: any) => {
    setFromTimeFeild(e.target.value);
    setToTimeFeild("Select end time");
    setStartTimeMessage("");
    setEndTimeMessage("End Time is required");
    handleChange({
      target: { name: "fromTime", value: e.target.value },
    });
    // handleChange({
    //   target: { name: "toTime", value: null }
    // });
  };
  // console.log(DurationFeildValue);
  const [myTime, setMyTime] = useState(8);

  const handleDateChange = (name: any, value: any) => {
    const curDate = new Date();

    // console.log(curDate.getDate(), "current Date");
    if (
      curDate.getDate() === value.getDate() &&
      curDate.getMonth() === value.getMonth() &&
      curDate.getFullYear() === value.getFullYear()
    ) {
      // console.log("success");
      setMyTime(curDate.getHours());
      // myTime = curDate;
      // generateTimeList(curDate.getHours())
    } else {
      setMyTime(8);
    }
    setFormatedBookingDateTime(value);
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleSubmit = (e: any) => {
    setCheck(true);
    e.preventDefault();
    // console.log(editFormData);
  };

  const backScreen = () => {
    navigate("/management/listbooking");
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Booking Management",
      route: "",
    },
    {
      id: "2",
      text: "List of Booking",
      route: "/management/listbooking",
    },
    {
      id: "3",
      text: views ? "View List of Booking" : "Edit List of Booking",
      route: "",
    },
  ];
  //Timelist for from and to time\
  const generateTimeList = (): string[] => {
    const times: string[] = [];
    let currentTime = new Date();

    let currenthour = myTime === 8 ? 8 : myTime + 1;
    currentTime.setHours(currenthour, 0, 0, 0); // Start at 08:00

    const endTime = new Date();
    endTime.setHours(19, 0, 0, 0); // End at 19:00

    while (currentTime <= endTime) {
      const timeString = currentTime.toLocaleTimeString([], {
        hour: "2-digit", // 2-digit hour (leading zero)
        minute: "2-digit",
        hour12: false, // 24-hour format
      });

      times.push(timeString);
      currentTime.setMinutes(currentTime.getMinutes() + 60); // Increment by 60 minutes
    }
    return times;
  };
  const filterToTimeList = (timeList: any, fromTime: any) => {
    if (!fromTime) return timeList;

    const fromTimeIndex = timeList.indexOf(fromTime);
    const oneHourLaterIndex = fromTimeIndex + 4; // 4 intervals of 15 minutes = 1 hour

    // Return a subset of the timeList, starting from just after fromTime, up to 1 hour later
    // return timeList.slice(fromTimeIndex + 4, Math.min(oneHourLaterIndex + 1, timeList.length));
    return timeList.slice(fromTimeIndex + 4, Math.min(timeList.length));
  };

  const timeList = generateTimeList();
  // console.log("Timelist", timeList, "SelectedFromTime", editFormData.fromTime);
  const filteredToTimeList = filterToTimeList(timeList, editFormData.fromTime);
  const [advancevalidation, setAdvanceValidationMessage] = useState("");
  const [costvalidation, setCostValidationMessage] = useState("");
  const [refundvalidation, setRefundValidationMessage] = useState("");
  const [seatsvalidation, setSeatsValidationMessage] = useState("");
  const [notesvalidation, setNotesValidationMessage] = useState("");

  // const handleBlurAdvance = () => {
  //   if (deposit === 0) {
  //     setAdvanceValidationMessage(advanceValidationMessage);
  //   } else {
  //     setAdvanceValidationMessage('');
  //   }
  // };
  // const handleBlurCost = () => {
  //   if (editFormData.roomcost === "") {
  //     setCostValidationMessage(costValidationMessage);
  //   } else {
  //     setCostValidationMessage("");
  //   }
  // };
  const handleBlurRefund = () => {
    if (editFormData.refund === "") {
      setRefundValidationMessage(refundValidationMessage);
    } else {
      setRefundValidationMessage("");
    }
  };
  // const handleBlurSeats = () => {
  //   if (editFormData.refund === "") {
  //     setSeatsValidationMessage(seatsValidationMessage);
  //   } else {
  //     setSeatsValidationMessage("");
  //   }
  // };
  // const handleBlurNotes = () => {
  //   if (editFormData.refund === "") {
  //     setNotesValidationMessage(notesValidationMessage);
  //   } else {
  //     setNotesValidationMessage("");
  //   }
  // };

  const [createdDate, timeWithZone1] = editFormData.CreatedOn.split("T");
  const [bookedDate, timeWithZone2] = editFormData.BookingDatetime.split("T");
  const [formatedBookingCreationDate, setFormatedBookingCreationDateTime] =
    useState(new Date(createdDate));
  const [formatedBookingDateTime, setFormatedBookingDateTime] = useState(
    new Date(bookedDate)
  );
  const [selectedPackageId, setSelectedPackageId] = useState(0);
  const [rawStart, setRawStart] = useState(
    editFormData.RawpowerStartingUnit | 0
  );
  const [rawEnd, setRawEnd] = useState(editFormData.RawpowerClosingUnit | 0);
  const [upsStart, setUpStart] = useState(
    editFormData.UpspowerStartingUnit | 0
  );
  const [upsEnd, setUpsEnd] = useState(editFormData.UpspowerClosingUnit | 0);
  const [overallUnit, setOverallUnit] = useState(
    editFormData.TotalUnitConsumed | 0
  );
  const [unitCost, setUnitCost] = useState(editFormData.PerUnitCost | 0);
  const [overallEB, setOverallEB] = useState(editFormData.OverallCharges | 0);
  const [coffee, setCoffee] = useState(editFormData.CoffeeTea | 0);
  const [meals, setMeals] = useState(editFormData.Meals | 0);
  const [miscellaneous, setMiscellaneous] = useState(
    editFormData.Miscellaneous | 0
  );
  const [otherAmount, setOtherAmount] = useState(editFormData.OtherAmount | 0);
  const [deposit, setDeposit] = useState(editFormData.Deposit | 0);
  const [spacerent, setSpacerent] = useState(editFormData.TotalAmount | 0);
  const [collect, setCollect] = useState(editFormData.NeedToCollect | 0);
  const [refund, setRefund] = useState(editFormData.Refund | 0);

  // Basic validation
  const [companyNameMessage, setcompanyNameMessage] = useState("");
  const [customerNameMessage, setCustomerNameMessage] = useState("");
  const [customerEmailMessage, setCustomerEmailMessage] = useState("");
  const [customerNumberMessage, setCustomerNumberMessage] = useState("");
  const [companyAddressMessage, setCompanyAddressMessage] = useState("");
  const [startTimeMessage, setStartTimeMessage] = useState("");
  const [endTimeMessage, setEndTimeMessage] = useState("");

  const mobileRegex = /^[6-9][0-9]{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const defaultFromTimeValue = "Select From Time";
  const defaultToTimeValue = "Select To Time";
  const [subTotal, setsubTotal] = useState(0);
  const [subTotalIncrease, setsubTotalIncrease] = useState(0);
  const [gstPercentage, setGstPercentage] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [selectedDurationID, setSelectedDurationID] = useState<string>();
  const [selectedFromTime, setSelectedFromTime] =
    useState(defaultFromTimeValue);
  const [selectedToTime, setSelectedToTime] = useState(defaultToTimeValue);
  const [isCheckHourly, setIsCheckHourly] = useState(true);

  //
  const [durationFeild, setDurationType] = useState(editFormData.DurationId);
  useEffect(() => {
    const DurationApi = async (spaceId: any) => {
      try {
        const response = await axios.get(
          `https://preprod-api.workfromhaven.in/odata/SpaceMappings?$filter=SpaceId%20eq%20${spaceId}`
        );
        setDurationList(response.data.value);
        for (let d = 0; d < response.data.value.length; d++) {
          if (infoId.DurationId === response.data.value[d].DurationId) {
            setSelectedDuration(response.data.value[d]);
            let rent =
              response.data.value[d].RentAmount +
              response.data.value[d].RentAmount;
            break;
          }
        }
      } catch (err) {}
    };

    const DocumentApi = async () => {
      try {
        const response = await axios.get(
          `https://preprod-api.workfromhaven.in/odata/DocumentUploads?$filter=refno eq ${infoId.Id}`
        );
        documents = response.data.value[0];
        setKycDocuments(documents);
        setdocument(response.data.value);
        // console.log("Documents", response.data.value[0]);
        // console.log("Setted Document", kycDocuments);
        // console.log("Var Documents",documents);
        setFileName(formatFileName(documents.Name, documents.Type));
      } catch (err) {}
    };

    const PackageApi = async () => {
      try {
        const response = await axios.get(
          `https://preprod-api.workfromhaven.in/odata/PackageDetails`
        );
        setPackageList(response.data.value);
        // console.log("Package List from api", response.data.value);
        for (let d = 0; d < response.data.value.length; d++) {
          if (parseInt(infoId.PackageSelection) === response.data.value[d].Id) {
            // console.log(
            //   "Selected Package Selection",
            //   response.data.value[d].Id
            // );
            setParticularPackageData(response.data.value[d].PackageName);
            setSelectedPackage(response.data.value[d]);
            setSelectedPackageId(response.data.value[d].id);
            // console.log("Selected Package Value", selectedPackage);
            break;
          }
        }
        // console.log("Package List", packageList);
        // StatusApi();
      } catch (err) {}
    };

    const StatusApi = async () => {
      try {
        const response = await axios.get(
          `https://preprod-api.workfromhaven.in/odata/Status`
        );
        setStatusList(response.data);
        // console.log("Status List", statusList);
        for (let s = 0; s < response.data.length; s++) {
          // console.log("Status Value", response.data[s].id);
          if (editFormData.BookingStatus === response.data[s].id) {
            // console.log("Given Response", response.data);
            setSelectedItem(response.data[s]);
            // setBookingStatus(response.data[s].name);
            // console.log("Selected Status Value", response.data[s].id);
            break;
          }
        }
      } catch (err) {}
    };

    const SpacesApi = async () => {
      try {
        const response = await axios.get(
          `https://preprod-api.workfromhaven.in/odata/Spaces`
        );
        setSpaceList(response.data.value);
        for (let d = 0; d < response.data.value.length; d++) {
          if (editFormData.SpaceId === response.data.value[d].Id) {
            setSelectedSpace(response.data.value[d]);
            setSelectedSpaceId(response.data.value[d].Id);
            DurationApi(response.data.value[d].Id);
            break;
          }
        }
        StatusApi();
      } catch (err) {}
    };
    const KycApi = async () => {
      try {
        const response = await axios.get(
          `https://preprod-api.workfromhaven.in/odata/KycDocuments`
        );
        setKycList(response.data.value);
        for (let d = 0; d < response.data.value.length; d++) {
          if (editFormData.kycDocumentId === response.data.value[d].Id) {
            setSelectedKyc(response.data.value[d]);
            // console.log("Selected KYC", selectedKyc);
            break;
          }
        }
        StatusApi();
      } catch (err) {}
    };
    if (firsttime === true) {
      DocumentApi();
      PackageApi();
      StatusApi();
      SpacesApi();
      KycApi();
      setFirstTime(false);
    }
    if (rawEnd >= rawStart && upsEnd >= upsStart) {
      calculationPart();
    } else {
      setOverallUnit(0);
      setOverallEB(0);
      setOtherAmount(0);
      setRefund(0);
      setCollect(0);
    }
  }, [
    rawEnd,
    upsEnd,
    rawStart,
    upsStart,
    unitCost,
    coffee,
    meals,
    miscellaneous,
    otherAmount,
    deposit,
    spacerent,
    collect,
    refund,
  ]);
  const calculationPart = () => {
    var overall = rawEnd - rawStart + (upsEnd - upsStart);
    // console.log("Overall Calculated value", overall);
    setOverallUnit(Math.round(overall * 10) / 10);
    var allEB = overall * unitCost;
    // console.log("OverAllEbCharges", allEB);
    setOverallEB(Math.round(allEB * 10) / 10);
    var food = coffee - 0 + (meals - 0) + (miscellaneous - 0);
    // console.log("Food", food);
    var others = food + allEB;
    // console.log("Other Expenses", others);
    setOtherAmount(Math.round(others * 10) / 10);
    var totalcollection = spacerent - 0 + others - deposit;
    // console.log("Total Balance", totalcollection);
    if (totalcollection > 0) {
      setCollect(Math.round(totalcollection * 10) / 10);
      setRefund(0);
    } else {
      setCollect(0);
      // console.log(totalcollection);
      setRefund((Math.round(totalcollection * 10) / 10) * -1);
    }
  };
  const updateBookingDetails = () => {
    setCheck(true);
    // console.log(
    //   "valid",
    //   rawEndMessage.trim(),
    //   upsEndMessage.trim(),
    //   editFormData.CompanyName.trim(),
    //   editFormData.CustomerName.trim(),
    //   editFormData.Email.trim(),
    //   editFormData.Address.trim(),
    //   customerEmailMessage,
    //   customerNumberMessage,
    //   startTimeMessage,
    //   endTimeMessage
    // );
    if (
      editFormData.CustomerType === "Company" &&
      rawEndMessage.trim() === "" &&
      rawStartMessage.trim() === "" &&
      upsStartMessage.trim() === "" &&
      upsEndMessage.trim() === "" &&
      editFormData.CompanyName.trim() !== "" &&
      editFormData.CustomerName.trim() !== "" &&
      editFormData.Email.trim() != "" &&
      editFormData.Address.trim() !== "" &&
      customerEmailMessage === "" &&
      customerNumberMessage === "" &&
      startTimeMessage === "" &&
      endTimeMessage === ""
    ) {
      // console.log("Success Company");
      BookingApi();
    } else if (
      editFormData.CustomerType === "Individual" &&
      rawEndMessage.trim() === "" &&
      rawStartMessage.trim() === "" &&
      upsStartMessage.trim() === "" &&
      upsEndMessage.trim() === "" &&
      editFormData.CustomerName.trim() !== "" &&
      editFormData.Email.trim() != "" &&
      editFormData.Address.trim() !== "" &&
      customerEmailMessage === "" &&
      customerNumberMessage === "" &&
      startTimeMessage === "" &&
      endTimeMessage === ""
    ) {
      // console.log("Success Individual");
      BookingApi();
    }
  };

  const appendTimeToDate = (selectedDate: any, timeStr: any) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}T${timeStr}:00.000Z`; // Outputs: 2024-08-16T10:00:00+05:30
  };

  // const addedTime = (dateStr: any) => {
  //   // Split the date string into day, month, and year
  //   const [day, month, year] = dateStr.split("/").map(Number);

  //   // Create a Date object in the local timezone
  //   const date = new Date(year, month - 1, day); // Month is zero-indexed

  //   // Convert the Date object to an ISO string (server time format)
  //   const serverDateTime = date.toISOString();

  //   console.log(serverDateTime); // Outputs: 2024-08-14T18:30:00.000Z (if local time is UTC+5:30)
  // };

  const BookingApi = async () => {
    setLoading(true);
    // const bookingFromDate = new Date(editFormData.BookingDatetime);
    // const formatedBookingFromData = bookingFromDate.toISOString();
    // console.log(
    //   "booking date",
    //   bookingDateTimeFormate(formatedBookingDateTime)
    // );
    // console.log("from date", appendTimeToDate(fromTimeFeild));
    // console.log("to date", appendTimeToDate(toTimeFeild));
    // console.log("to date format ", toTimeFeild);

    setLoading(true);
    let formSubmit = {
      id: editFormData.Id,
      userId: editFormData.UserId,
      roomTypeId: editFormData.SpaceId,
      taxId: editFormData.TaxId,
      bookingId: editFormData.BookingId,
      bookingDatetime: bookingDateTimeFormate(formatedBookingDateTime),
      noOfSeats: editFormData.NoOfSeats,
      bookingForId: editFormData.BookingForId,
      address: editFormData.Address,
      companyName: editFormData.CompanyName,
      kycDocumentId: selectedKyc.Id,
      gstNumber: editFormData.GSTNumber,
      notes: editFormData.Notes,
      customerId: editFormData.CustomerId,
      customerName: editFormData.CustomerName,
      customerType: editFormData.CustomerType,
      contactNo: editFormData.ContactNo,
      email: editFormData.Email,
      totalAmount: editFormData.TotalAmount,
      discountPercentage: editFormData.DiscountPercentage,
      discountValue: editFormData.DiscountValue,
      packageSelection: editFormData.PackageSelection,
      bookingFrom:
        fromTimeFeild !== ""
          ? formatMomentTime(infoId.BookingFrom, "YYYY-MM-DDTHH:mm:ss[Z]")
          : formatMomentTime(infoId.BookingFrom, "YYYY-MM-DDTHH:mm:ss[Z]"),
      bookingTo:
        toTimeFeild !== ""
          ? formatMomentTime(infoId.BookingTo, "YYYY-MM-DDTHH:mm:ss[Z]")
          : formatMomentTime(infoId.BookingTo, "YYYY-MM-DDTHH:mm:ss"),
      bookingStatus: editFormData.BookingStatus,
      paymentStatus: editFormData.PaymentStatus,
      deposit: editFormData.Deposit, //ssc
      spaceRent: spacerent,
      otherAmount: otherAmount,
      rawpowerStartingUnit: rawStart,
      rawpowerClosingUnit: rawEnd,
      upspowerStartingUnit: upsStart,
      upspowerClosingUnit: upsEnd,
      totalUnitConsumed: overallUnit,
      perUnitCost: unitCost,
      overallCharges: overallEB,
      coffeeTea: coffee,
      meals: meals,
      miscellaneous: miscellaneous,
      needToCollect: collect,
      refund: refund,
      durationId: editFormData.DurationId,
      spaceId: selectedSpaceId === 0 ? 1 : selectedSpaceId,
    };

    // console.log("Updated from time field",fromTimeFeild);
    // console.log("Updated from time ",editFormData.BookingFrom);
    // console.log("Updated To time field",toTimeFeild);
    // console.log("Updated To time ",editFormData.BookingTo);

    // console.log("",editFormData)

    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(
        `https://preprod-api.workfromhaven.in/odata/UpdateBooking?key=${infoId.Id}`,
        formSubmit
      );
      // console.log("Response", response);
      setLoading(false);
      setAlertContent("Booking Details Updated Successfully");
      setVisible(true);
      setSuccess(true);
    } catch (err: any) {
      if (err.response.status === 500) {
        setAlertContent(`${err.response.data.detail}`);
        setLoading(false);
        setVisible(true);
      }
    }
    // console.log("Submit Form Data", formSubmit);
  };

  // var toTime = "Select to time";
  // interface DropDownItem {
  //   id: number;
  //   name: string;
  //   description: string;
  //   createdBy: number;
  //   createdOn: string;
  //   isActive: boolean;
  //   isDelete: boolean;
  //   modifiedBy: number | null;
  //   modifiedOn: string | null;
  // }
  // // const itemsMap = new Map<number, DropDownItem>(
  // //   statusList.map(item => [item.id, item])
  // // );
  // const setValueById = (id: number | null) => {
  //   // setSelectedItem(id !== null ? itemsMap.get(id) || null : null);
  // };
  // const handleChangedropdown = (event: any) => {
  //   setSelectedItem(event.target.value);
  //   console.log("Selected item:", event.target.value); // Send or log the ID
  //   console.log("Selected ID:", event.target.value.id); // Send or log the ID
  // };
  const handleChangedropdownduration = (event: any) => {
    // console.log("selected Space dd:", event.value.DepositAmmount);

    // setSpacerent(event.value.RentAmount);
    setDeposit(event.value.DepositAmmount);
    // console.log("Selectd Value :", event.target.value.Id);
    setDurationType(event.target.value.Id);
    setSelectedDuration(event.target.value);
    // console.log("selected Duration:", event.target.value); // Send or log the ID
    // console.log("selected Duration id:", event.target.value.Id); // Send or log the ID

    if (event.value.DurationName === "Select Duration") {
      calculateBookingAmount(0, 0, "", 0);
      setIsCheckHourly(true);
    } else if (event.value.DurationName === "Hourly") {
      setFromTimeFeild("Select start time");
      setToTimeFeild("Select end time");
      setStartTimeMessage("Start Time is required");
      setEndTimeMessage("End Time is required");
      setsubTotal(event.value.RentAmount);
      setGstPercentage(event.value.Tax);
      calculateBookingAmount(0, 0, "Hourly", 0);
      setIsCheckHourly(false);
      setSelectedDurationID(event.value.Id);
      setDepositAmount(event.value.DepositAmmount);
    } else {
      calculateBookingAmount(
        event.value.RentAmount,
        event.value.Tax,
        "Others",
        0
      );
      setIsCheckHourly(true);
      setSelectedFromTime(defaultFromTimeValue);
      setSelectedToTime(defaultToTimeValue);
      setSelectedDurationID(event.value.Id);
      setDepositAmount(event.value.DepositAmmount);
    }
  };
  // const handleChangedropdownspace = (event: any) => {
  //   setSelectedSpace(event.value);
  //   setSelectedSpaceId(event.value.Id);
  //   console.log("selected Space:", event.target.value); // Send or log the ID
  //   console.log("selected Space id:", event.value.Id); // Send or log the ID
  // };
  // const handleChangedropdownpackgae = (event: any) => {
  //   setSelectedPackage(event.value);
  //   setSelectedPackageId(event.value.Id);
  //   console.log("selected Package:", event.value); // Send or log the ID
  //   console.log("selected Package id:", selectedPackage); // Send or log the ID
  // };
  const handleChangedropdownkyc = (event: any) => {
    setSelectedKyc(event.value);
    // console.log("selected Kyc:", event.target.value); // Send or log the ID
    // console.log("selected Kyc id:", event.target.value.Id); // Send or log the ID
  };
  // https://preprod-api.workfromhaven.in/odata/Durations

  const handleInputChange = (e: any, setter: any) => {
    // if (/^\d*$/.test(e.target.value)) {
    //   setter(e.target.value);
    // }

    const regex = /^\d*\.?\d{0,1}$/;
    // Validate the input value
    if (regex.test(e.target.value)) {
      const value = Number(e.target.value);
      setter(value);
    }
  };
  const [firsttime, setFirstTime] = useState(true);
  // const calculateResult = () => {
  //   // Perform the calculation
  //   // var D = rawEnd - rawStart + upsEnd - upsStart;
  //   // const D = rawEnd  + upsEnd - UpsStart - rawStart;

  //   // Set the result state
  //   // setOverallUnit(D);
  //   console.log(overallUnit);
  // };

  const handleblurRaw = () => {
    if (rawEnd < rawStart) {
      setRawEndMessage(rawendValidationMessage);
    } else if (rawEnd !== 0 && rawStart === 0) {
      setRawStartMessage(rawStartValidationMessage);
    } else {
      setRawEndMessage("");
      setRawStartMessage("");
    }
    // setRawCheck(true);
  };
  const handleblurUps = () => {
    if (upsEnd < upsStart) {
      setUpsEndMessage(upsendValidationMessage);
    } else if (upsEnd !== 0 && upsStart === 0) {
      setUpsStartMessage(upsStartValidationMessage);
    } else {
      setUpsEndMessage("");
      setUpsStartMessage("");
    }
    // setUpsCheck(true);
  };

  // const handlePreview = (url: any) => {
  //   window.open(url, "_blank");
  // };
  // const handleDownload = (url: any, filename: any) => {
  //   axios
  //     .get(url, {
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       console.log("Image Response->", res);
  //     });
  // };
  // function onClick() {
  //   handleDownload(
  //     "https://cws-dev-stor.equadriga.in/User/64/9638bd93-236f-4289-9772-001ac6dd9d2b_Screenshot%202024-08-09%20at%2012.41.55.png",
  //     fileName
  //   );
  // }

  // Basic Validation
  const handleValueSet = (
    e: any,
    editor: any,
    json: any,
    errorMessge: any,
    flow: any
  ) => {
    if (json === "" && flow === "") {
      editor(errorMessge);
    } else if (
      (json.length < 10 || !mobileRegex.test(json)) &&
      flow === "mobile"
    ) {
      editor("Invalid mobile number and must be 10 digits");
    } else if ((json === "" || !emailRegex.test(json)) && flow === "email") {
      editor("Invalid email address");
    } else {
      editor("");
    }
  };

  // Timer hande flow
  const handleToTimeChange = (event: any) => {
    const selectedToTime = event.target.value;
    const { name, value } = event.target;
    // console.log("target", value, "value", selectedToTime);
    setToTimeFeild(value);
    setSelectedToTime(selectedToTime);
    setEndTimeMessage("");

    let fromTime: String = fromTimeFeild!;
    // console.log("from", fromTime, "to", value);
    // Parse hours and minutes from the time strings
    const [fromHours, fromMinutes] = fromTime.split(":").map(Number);
    const [toHours, toMinutes] = selectedToTime.split(":").map(Number);

    // Create Date objects for fromTime and toTime
    let fromDate = new Date();
    fromDate.setHours(fromHours, fromMinutes, 0, 0);

    let toDate = new Date();
    toDate.setHours(toHours, toMinutes, 0, 0);

    // Calculate the difference in hours
    const hoursDifference =
      (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60);
    // console.log(hoursDifference);
    calculateBookingAmount(0, 0, "Hourly", hoursDifference);
  };

  const calculateHoursDifference = (
    fromTime: string,
    toTime: string
  ): number => {
    const [fromHours, fromMinutes, fromPeriod] = fromTime.split(/[: ]/);
    let fromDate = new Date();
    fromDate.setHours(
      fromPeriod === "AM" ? parseInt(fromHours) : parseInt(fromHours) + 12,
      parseInt(fromMinutes),
      0,
      0
    );

    const [toHours, toMinutes, toPeriod] = toTime.split(/[: ]/);
    let toDate = new Date();
    toDate.setHours(
      toPeriod === "AM" ? parseInt(toHours) : parseInt(toHours) + 12,
      parseInt(toMinutes),
      0,
      0
    );

    return (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60);
  };

  const getFilteredToTimeList = (): string[] => {
    if (!fromTimeFeild) return [];

    const filteredTimes = timeList.filter((time) => {
      const hoursDifference = calculateHoursDifference(fromTimeFeild, time);
      return hoursDifference >= 1 && hoursDifference <= 8;
    });
    return filteredTimes;
  };

  const calculateBookingAmount = (
    RentAmount: any,
    Tax: any,
    checkFlow: any,
    difference: any
  ) => {
    let subTotalValue = RentAmount;
    let gstValue = Tax;
    if (checkFlow === "Others") {
      setsubTotalIncrease(subTotalValue);
      const calculateTax = (subTotalValue * gstValue) / 100;
      const calculateTotal = subTotalValue + calculateTax;
      setTaxAmount(calculateTax);
      setSpacerent(calculateTotal);
    } else if (checkFlow === "Hourly" && difference !== 0) {
      let subTotalValue = subTotal * difference;
      setsubTotalIncrease(subTotalValue);
      const calculateTax = (subTotalValue * gstPercentage) / 100;
      const calculateTotal = subTotalValue + calculateTax;
      setTaxAmount(calculateTax);
      setSpacerent(calculateTotal);
      // console.log("sub total", subTotal, "difference", difference);
      // console.log("tax total", calculateTax, "total", calculateTotal);
    } else {
      setsubTotalIncrease(0);
      setTaxAmount(0);
      setSpacerent(0);
    }
  };

  const handlePreview = (url: any) => {
    window.open("https://preprod-stor.workfromhaven.in/" + url, "_blank");
  };

  const handleDownload = (url: any, filename: any) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename + "." + document[0]["Type"]);
        setLoading(false);
        // console.log("Image Response->", res);
      });
  };

  const [document, setdocument] = useState([]);
  function onClickdata(url: any, filename: any) {
    setLoading(true);
    handleDownload("https://preprod-stor.workfromhaven.in/" + url, filename);
  }

  const [visible, setVisible] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  function updateDialog() {
    success ? navigate("/management/listbooking") : setVisible(false);
  }

  const [alertContent, setAlertContent] = useState(""); // State for loading
  const [loading, setLoading] = useState(false); // State for loading

  return (
    <div>
      {loading ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view ">
          <div>
            {visible && (
              <Dialog title={"Message"} onClose={updateDialog}>
                <p style={{ margin: "25px", textAlign: "center" }}>
                  {alertContent}
                  {/* Booking Details Updated Successfully */}
                </p>
                <DialogActionsBar>
                  <Button type="button" onClick={updateDialog}>
                    Ok
                  </Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            <Breadcrumbs breadCrumbData={breadCrumbsData} />
            <hr className="hr-border" />
          </div>
          <div className="common-margina edit-booking-margin">
            <form className="editForm" onSubmit={handleSubmit}>
              <div className="form-row-edit">
                {/* <div className="form-row"> */}
                <div className="form-group">
                  <label>Booking ID</label>

                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="BookingId"
                    value={editFormData.BookingId}
                    onChange={handleChange}
                    readOnly={true}
                  />
                </div>
                <div className="form-group">
                  <label>Booking Creation Date</label>
                  <DatePicker
                    show={false}
                    className="book-input-view book-height-edit disable-fields"
                    value={formatedBookingCreationDate}
                    onChange={(e) => handleDateChange("bookeddate", e.value)}
                    format="dd/MM/yyyy"
                  />
                </div>
                <div className="form-group"></div>
                <div className="form-group">
                  <label>Space Type</label>

                  {/* <DropDownList
                svgIcon={chevronDownIcon}
                className="book-dropdown-new"
                data={spaceList}
                disabled={false}
                required={check}
                name="roomtype"
                value={selectedSpace}
                style={{}}
                dataItemKey="Id"
                textField="SpaceType"
                defaultItem={{ Id: null, SpaceType: "Select Space Type" }}
                validationMessage={roomValidationMessage}
                onChange={handleChangedropdownspace}
              /> */}

                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="bookingid"
                    value={spaceFeildValue}
                    onChange={handleChange}
                    readOnly={true}
                  />
                </div>
                <div className="form-group">
                  <label>Package</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="package"
                    value={particularPackageData}
                    onChange={handleChange}
                    readOnly={true}
                  />
                  {/* {views === undefined ? (
                <DropDownList
                  svgIcon={chevronDownIcon}
                  className="book-dropdown-new disable-fields"
                  data={packageList}
                  name="package"
                  value={selectedPackage}
                  required={check}
                  dataItemKey="Id"
                  textField="PackageName"
                  defaultItem={{ Id: null, PackageName: "Select Package Type" }}
                  validationMessage={packageValidationMessage}
                  onChange={handleChangedropdownpackgae}
                />
              ) : (
                <Input
                  className="book-input-view book-height-edit "
                  name="bookingid"
                  value={packageFeildValue}
                  onChange={handleChange}
                  readOnly={true}
                />
              )} */}
                </div>
                <div className="form-group">
                  <label>Booking Date</label>
                  <DatePicker
                    // show={views === true ? false : undefined}
                    show={false}
                    className="book-input-view book-height-edit disable-fields"
                    value={formatedBookingDateTime}
                    placeholder={"DD/MM/YYYY"}
                    onChange={(e) => handleDateChange("bookeddate", e.value)}
                    format={"dd/MM/yyyy"}
                    min={new Date()}
                  />
                </div>
                <div className="form-group">
                  <label>Duration</label>
                  {views === undefined ? (
                    <DropDownList
                      svgIcon={chevronDownIcon}
                      className="book-dropdown-new disable-fields"
                      data={durationList}
                      name="duration"
                      disabled={true}
                      required={check}
                      validationMessage={durationValidationMessage}
                      dataItemKey="Id"
                      textField="DurationName"
                      defaultItem={{
                        Id: null,
                        DurationName: "Select Duration",
                      }}
                      value={selectedDuration}
                      onChange={handleChangedropdownduration}
                      // onChange={(e) =>
                      // {
                      //   console.log("Values passed by duration",e.target.value.Id)
                      //   handleChange({
                      //     target: { name: "duration", value: e.target.value.Id},
                      //   })
                      // }}
                    />
                  ) : (
                    <Input
                      className="book-input-view book-height-edit disable-fields"
                      name="bookingid"
                      value={DurationFeildValue}
                      onChange={handleChange}
                      readOnly={true}
                    />
                  )}
                </div>
                <div className="form-group">
                  {selectedDuration !== null &&
                  selectedDuration["DurationName"] === "Hourly" ? (
                    <div>
                      {" "}
                      <label>Start Time</label>
                      {views === undefined ? (
                        <DropDownList
                          svgIcon={chevronDownIcon}
                          className="book-dropdown-new disable-fields"
                          data={timeList}
                          name="fromTime"
                          disabled={true}
                          value={fromTimeFeild}
                          required={check}
                          validationMessage={fromValidationMessage}
                          onChange={(e) => handletimechange(e)}
                        />
                      ) : (
                        <Input
                          className="book-input-view book-height-edit disable-fields"
                          name="bookingid"
                          value={fromTimeFeild}
                          onChange={handleChange}
                          readOnly={true}
                        />
                      )}
                      {startTimeMessage && <Error>{startTimeMessage}</Error>}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="form-group">
                  {selectedDuration !== null &&
                  selectedDuration["DurationName"] === "Hourly" ? (
                    <div>
                      <label>End Time</label>
                      {views === undefined ? (
                        <DropDownList
                          svgIcon={chevronDownIcon}
                          className="book-dropdown-new disable-fields"
                          data={getFilteredToTimeList()}
                          name="bookingTo"
                          value={toTimeFeild}
                          // disabled={!fromTimeFeild} // Disable toTime until fromTime is selected
                          disabled={true} // Disable toTime until fromTime is selected
                          required={check}
                          validationMessage={toValidationMessage}
                          onChange={handleToTimeChange}
                        />
                      ) : (
                        // <DropDownList
                        //   svgIcon={chevronDownIcon}
                        //   className="book-dropdown-new"
                        //   data={filteredToTimeList}
                        //   name="toTime"
                        //   value={toTimeFeild}
                        //   disabled={!fromTimeFeild}
                        //   required={check}
                        //   validationMessage={toValidationMessage}
                        //   onChange={
                        //     (e) => setToTimeFeild(e.target.value)
                        //     // handleChange({
                        //     //   target: { name: "toTime", value: e.target.value },
                        //     // })
                        //   }
                        // />
                        <Input
                          className="book-input-view book-height-edit  disable-fields"
                          name="bookingid"
                          value={toTimeFeild}
                          onChange={handleChange}
                          readOnly={true}
                        />
                      )}
                      {endTimeMessage && <Error>{endTimeMessage}</Error>}
                    </div>
                  ) : (
                    <div> </div>
                  )}
                </div>
                <div className="form-group">
                  <label>Customer ID</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="CustomerId"
                    value={editFormData.CustomerId}
                    onChange={handleChange}
                    readOnly={true}
                    required={check}
                    validationMessage="Please enter the Customer ID"
                  />
                </div>

                {/* </div> */}
                {/* <div className="form-row"> */}
                <div className="form-group">
                  <label>Customer Type</label>
                  {views === undefined ? (
                    <DropDownList
                      svgIcon={chevronDownIcon}
                      className="book-dropdown-new disable-fields"
                      data={customerTypes}
                      name="CustomerType"
                      disabled={true}
                      value={editFormData.CustomerType}
                      required={check}
                      validationMessage={customerValidationMessage}
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: "CustomerType",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  ) : (
                    <Input
                      className="book-input-view book-height-edit disable-fields"
                      name="bookingid"
                      value={editFormData.CustomerType}
                      // value={CustomeTypeFeildValue}
                      onChange={handleChange}
                      readOnly={true}
                    />
                  )}
                </div>
                {/* 
                  <div className="form-group">
                    {selectedDuration !== null &&
                    selectedDuration["DurationName"] === "Hourly" ? (
                      <div>
                        <label>End Time</label>
                        {views === undefined ? (
                          <DropDownList
                            svgIcon={chevronDownIcon}
                            className="book-dropdown-new"
                            data={getFilteredToTimeList()}
                            name="bookingTo"
                            value={toTimeFeild}
                            disabled={!fromTimeFeild} // Disable toTime until fromTime is selected
                            required={check}
                            validationMessage={toValidationMessage}
                            onChange={handleToTimeChange}
                          />
                        ) : (
                          // <DropDownList
                          //   svgIcon={chevronDownIcon}
                          //   className="book-dropdown-new"
                          //   data={filteredToTimeList}
                          //   name="toTime"
                          //   value={toTimeFeild}
                          //   disabled={!fromTimeFeild}
                          //   required={check}
                          //   validationMessage={toValidationMessage}
                          //   onChange={
                          //     (e) => setToTimeFeild(e.target.value)
                          //     // handleChange({
                          //     //   target: { name: "toTime", value: e.target.value },
                          //     // })
                          //   }
                          // />
                          <Input
                            className="book-input-view book-height-edit "
                            name="bookingid"
                            value={toTimeFeild}
                            onChange={handleChange}
                            readOnly={true}
                          />
                        )}
                        {endTimeMessage && <Error>{endTimeMessage}</Error>}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div> */}
                {/* </div> */}
                <div className="form-group">
                  {editFormData.CustomerType === "Company" ? (
                    <div>
                      <label>Company Name</label>
                      <Input
                        className="book-input-view book-height-edit disable-fields"
                        name="CompanyName"
                        value={editFormData.CompanyName}
                        onChange={handleChange}
                        readOnly={true}
                        onBlur={(e) =>
                          handleValueSet(
                            e,
                            setcompanyNameMessage,
                            editFormData.CompanyName.trim(),
                            "Company name is required",
                            ""
                          )
                        }
                      />
                      {companyNameMessage && (
                        <Error>{companyNameMessage}</Error>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Customer Name</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="CustomerName"
                    value={editFormData.CustomerName}
                    readOnly={true}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleValueSet(
                        e,
                        setCustomerNameMessage,
                        editFormData.CustomerName.trim(),
                        "Customer name is required",
                        ""
                      )
                    }
                  />
                  {customerNameMessage && <Error>{customerNameMessage}</Error>}
                </div>
                <div className="form-group">
                  <label>Contact Number</label>
                  <Input
                    maxLength={10}
                    className="book-input-view book-height-edit disable-fields"
                    name="ContactNo"
                    readOnly={true}
                    value={editFormData.ContactNo}
                    onChange={handleChange}
                    required={check}
                    validationMessage={contactValidationMessage}
                    onBlur={(e) =>
                      handleValueSet(
                        e,
                        setCustomerNumberMessage,
                        editFormData.ContactNo,
                        "Invalid mobile number and must be 10 digits",
                        "mobile"
                      )
                    }
                  />
                  {customerNumberMessage && (
                    <Error>{customerNumberMessage}</Error>
                  )}
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="Email"
                    readOnly={true}
                    value={editFormData.Email}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleValueSet(
                        e,
                        setCustomerEmailMessage,
                        editFormData.Email.trim(),
                        "Email is required",
                        "email"
                      )
                    }
                  />
                  {customerEmailMessage && (
                    <Error>{customerEmailMessage}</Error>
                  )}
                </div>
                <div className="form-group">
                  <label>Address</label>
                  <TextArea
                    className="book-input-view disable-fields"
                    name="Address"
                    rows={3}
                    readOnly={true}
                    value={editFormData.Address}
                    onChange={handleChange}
                    // onBlur={handleBlurNotes}
                  />
                  {companyAddressMessage && (
                    <Error>{companyAddressMessage}</Error>
                  )}
                </div>
                <div className="form-group">
                  <label>GST No</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="GSTNumber"
                    readOnly={true}
                    value={editFormData.GSTNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>Notes</label>
                  <TextArea
                    className="book-input-view disable-fields"
                    name="Notes"
                    rows={3}
                    readOnly={true}
                    value={editFormData.Notes}
                    onChange={handleChange}
                    // onBlur={handleBlurNotes}
                  />
                  {notesvalidation && <Error>{notesvalidation}</Error>}
                </div>
                {editFormData.customerType === "Individual" ? (
                  <div className="form-group"></div>
                ) : null}
                <div className="form-group">
                  <label>KYC Documents</label>
                  {views === undefined ? (
                    <DropDownList
                      svgIcon={chevronDownIcon}
                      className="book-dropdown-new disable-fields"
                      data={kycList}
                      name="kyc"
                      value={selectedKyc}
                      dataItemKey="Id"
                      textField="Name"
                      defaultItem={{ Id: null, Name: "Select KYC" }}
                      valid={selectedKyc.Id != null}
                      onChange={handleChangedropdownkyc}
                      required={check}
                      disabled={true}
                      validationMessage={KycValidationMessage}
                      //  onChange={(e) => handletimechange(e)}
                    />
                  ) : (
                    <Input
                      className="book-input-view book-height-edit disable-fields"
                      name="bookingid"
                      value={KYCTypeFeildValue}
                      onChange={handleChange}
                      readOnly={true}
                    />
                  )}
                  {selectedKyc.Id === null && (
                    <Error>{KycValidationMessage}</Error>
                  )}
                </div>
                <div className="form-group">
                  <div className="button-group-file">
                    <label>{fileName}</label>
                    <Button
                      className="eye-icon"
                      type="button"
                      svgIcon={eyeIcon}
                      onClick={() => handlePreview(document[0]["ImagePath"])}
                    ></Button>

                    <Button
                      className="download-icon"
                      type="button"
                      svgIcon={downloadIcon}
                      onClick={() =>
                        onClickdata(
                          document[0]["ImagePath"],
                          document[0]["Name"]
                        )
                      }
                      // onClick={() => props.enterView(props.dataItem)}
                    ></Button>
                  </div>
                </div>
                <div className="form-group"></div>
                <div className="form-group">
                  <label>Deposit</label>
                  <TextBox
                    name="Deposit"
                    type="number"
                    value={deposit}
                    readOnly={true}
                    className="book-height-edit disable-fields"
                    prefix={() => (
                      <>
                        <InputPrefix
                          style={{ paddingLeft: "20px", paddingRight: "20px" }}
                        >
                          ₹
                        </InputPrefix>
                      </>
                    )}
                    style={{ width: "80%" }}
                  />
                  {advancevalidation && <Error>{advancevalidation}</Error>}
                </div>
                <div className="form-group">
                  <label>Space Rent</label>
                  <TextBox
                    name="roomcost"
                    value={spacerent}
                    type="number"
                    readOnly={true}
                    className="book-height-edit disable-fields"
                    prefix={() => (
                      <>
                        <InputPrefix
                          style={{ paddingLeft: "20px", paddingRight: "20px" }}
                        >
                          ₹
                        </InputPrefix>
                      </>
                    )}
                    style={{ width: "80%" }}
                  />
                  {costvalidation && <Error>{costvalidation}</Error>}
                </div>
                <div className="form-group">
                  <label>Other Amount</label>
                  <TextBox
                    name="others"
                    readOnly={true}
                    value={otherAmount}
                    className="book-height-edit disable-fields"
                    onChange={(e) => handleInputChange(e, setOtherAmount)}
                    required={true}
                    prefix={() => (
                      <>
                        <InputPrefix
                          style={{ paddingLeft: "20px", paddingRight: "20px" }}
                        >
                          ₹
                        </InputPrefix>
                      </>
                    )}
                    style={{ width: "80%" }}
                  />
                </div>
                <div className="form-group"></div>
                <div className="form-group"></div>
              </div>
              <div className="form-group-multi-text rowset-value-padding">
                {/* //This Design */}
                <div className="rowset-value  filter-view">
                  <div className="form-group-short">
                    <label>Raw Power Start Unit</label>
                    <Input
                      name="rawstart"
                      readOnly={views}
                      type="number"
                      value={rawStart === 0 ? "" : rawStart}
                      className={
                        views === undefined
                          ? "raw-text "
                          : "raw-text disable-fields"
                      }
                      valid={rawStartMessage === ""}
                      onChange={(e) => handleInputChange(e, setRawStart)}
                      onBlur={handleblurRaw}
                      validationMessage={rawStartMessage}
                    />
                    {/* <TextBox
                  name="rawstart"
                  // type="number"
                  value={rawStart}
                  className="raw-text"
                  onChange={handleChange}
                  required={true}
                  // onBlur={handleBlurRefund}
                /> */}
                    {rawStartMessage && <Error>{rawStartMessage}</Error>}
                  </div>
                  <div className="form-group-short">
                    <label>Raw Power End Unit</label>
                    <TextBox
                      name="RawEnd"
                      type="number"
                      readOnly={views}
                      value={rawEnd === 0 ? "" : rawEnd}
                      className={
                        views === undefined
                          ? "raw-text "
                          : "raw-text disable-fields"
                      }
                      onChange={(e) => handleInputChange(e, setRawEnd)}
                      required={rawCheck}
                      valid={rawEnd >= rawStart}
                      onBlur={handleblurRaw}
                    />
                    {rawEndMessage && <Error>{rawEndMessage}</Error>}
                  </div>
                  <div className="form-group-short">
                    <label>UPS Power Start Unit</label>
                    <Input
                      name="upsstart"
                      readOnly={views}
                      type="number"
                      value={upsStart === 0 ? "" : upsStart}
                      className={
                        views === undefined
                          ? "raw-text "
                          : "raw-text disable-fields"
                      }
                      valid={upsStartMessage === ""}
                      onChange={(e) => handleInputChange(e, setUpStart)}
                      onBlur={handleblurUps}
                      validationMessage={upsStartMessage}
                    />
                    {upsStartMessage && <Error>{upsStartMessage}</Error>}
                  </div>
                  <div className="form-group-short">
                    <label>UPS Power End Units</label>
                    <TextBox
                      name="upsend"
                      readOnly={views}
                      value={upsEnd === 0 ? "" : upsEnd}
                      type="number"
                      onChange={(e) => handleInputChange(e, setUpsEnd)}
                      required={upsCheck}
                      valid={upsEnd >= upsStart}
                      onBlur={handleblurUps}
                      className={
                        views === undefined
                          ? "raw-text "
                          : "raw-text disable-fields"
                      }
                    />
                    {upsEndMessage && <Error>{upsEndMessage}</Error>}
                  </div>
                  <div className="form-group-short">
                    <label>Total Consumed Unit</label>
                    <TextBox
                      name="TotalUnit"
                      value={overallUnit}
                      className="raw-text disable-fields"
                      readOnly={true}
                      required={true}
                      // onBlur={handleBlurRefund}
                    />
                  </div>
                  <div className="form-group-short-withinput">
                    <label>Per Unit Cost</label>
                    <TextBox
                      name="unitcost"
                      type="number"
                      readOnly={views}
                      value={unitCost === 0 ? "" : unitCost}
                      onChange={(e) => handleInputChange(e, setUnitCost)}
                      required={true}
                      className={
                        views === undefined
                          ? "raw-text "
                          : "raw-text disable-fields"
                      }
                      prefix={() => (
                        <>
                          <InputPrefix
                            style={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            ₹
                          </InputPrefix>
                        </>
                      )}
                      // onBlur={handleBlurRefund}
                    />
                  </div>
                  <div className="form-group-short-withinput">
                    <label>Overall EB Charges</label>
                    <TextBox
                      name="overallEB"
                      value={overallEB}
                      className="raw-text disable-fields"
                      readOnly={true}
                      type="number"
                      onChange={(e) => handleInputChange(e, setOverallEB)}
                      required={true}
                      prefix={() => (
                        <>
                          <InputPrefix
                            style={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            ₹
                          </InputPrefix>
                        </>
                      )}
                      // onBlur={handleBlurRefund}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group rowset-value-padding">
                <div className="rowset filter-view">
                  <div className="form-group-short">
                    <label>Coffee/Tea</label>
                    <TextBox
                      name="coffee"
                      type="number"
                      readOnly={views}
                      value={coffee === 0 ? "" : coffee}
                      className={
                        views === undefined
                          ? "raw-text "
                          : "raw-text disable-fields"
                      }
                      onChange={(e) => handleInputChange(e, setCoffee)}
                      // required={true}
                      // onBlur={handleBlurRefund}
                      prefix={() => (
                        <>
                          <InputPrefix
                            style={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            ₹
                          </InputPrefix>
                        </>
                      )}
                    />
                  </div>
                  <div className="form-group-short">
                    <label>Meals</label>
                    <TextBox
                      name="meals"
                      readOnly={views}
                      value={meals === 0 ? "" : meals}
                      type="number"
                      className={
                        views === undefined
                          ? "raw-text "
                          : "raw-text disable-fields"
                      }
                      onChange={(e) => handleInputChange(e, setMeals)}
                      required={true}
                      // onBlur={handleBlurRefund}
                      prefix={() => (
                        <>
                          <InputPrefix
                            style={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            ₹
                          </InputPrefix>
                        </>
                      )}
                    />
                  </div>
                  <div className="form-group-short">
                    <label>Miscellaneous</label>
                    <TextBox
                      name="miscellaneous"
                      readOnly={views}
                      value={miscellaneous === 0 ? "" : miscellaneous}
                      type="number"
                      className={
                        views === undefined
                          ? "raw-text "
                          : "raw-text disable-fields"
                      }
                      onChange={(e) => handleInputChange(e, setMiscellaneous)}
                      required={true}
                      // onBlur={handleBlurRefund}
                      prefix={() => (
                        <>
                          <InputPrefix
                            style={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            ₹
                          </InputPrefix>
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="rowser-edit-end">
            <div className="form-group-sort-end">
              <label>Need to Collect</label>
              <TextBox
                name="balance"
                readOnly={views}
                value={collect}
                className="book-height-edit disable-fields"
                onChange={(e) => handleInputChange(e, setCollect)}
                required={true}
                onBlur={handleBlurRefund}
                prefix={() => (
                  <>
                    <InputPrefix
                      style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    >
                      ₹
                    </InputPrefix>
                  </>
                )}
                // style={{ width: "80%" }}
              />
              {refundvalidation && <Error>{refundvalidation}</Error>}
            </div>
            <div className="form-group-sort-end">
              <label>Refund</label>
              <TextBox
                name="refund"
                value={refund}
                readOnly={views}
                className="book-height-edit disable-fields"
                onChange={(e) => handleInputChange(e, setRefund)}
                required={true}
                onBlur={handleBlurRefund}
                prefix={() => (
                  <>
                    <InputPrefix
                      style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    >
                      ₹
                    </InputPrefix>
                  </>
                )}
                // style={{ width: "80%" }}
              />
              {refundvalidation && <Error>{refundvalidation}</Error>}
            </div>
          </div> */}
              <div className="rowser-edit-end">
                <div className="form-group-sort-end">
                  <label style={{ color: "#FF0404" }}>Need to Collect</label>
                  <TextBox
                    name="balance"
                    readOnly={true}
                    value={collect}
                    className="book-height-edit disable-fields-set"
                    onChange={(e) => handleInputChange(e, setCollect)}
                    required={true}
                    onBlur={handleBlurRefund}
                    prefix={() => (
                      <>
                        <InputPrefix
                          style={{ paddingLeft: "20px", paddingRight: "20px" }}
                        >
                          ₹
                        </InputPrefix>
                      </>
                    )}
                    // style={{ width: "80%" }}
                  />
                  {refundvalidation && <Error>{refundvalidation}</Error>}
                </div>
                <div className="form-group-sort-end">
                  <label style={{ color: "#42B677" }}>Refund</label>
                  <TextBox
                    name="refund"
                    value={refund}
                    readOnly={true}
                    className="book-height-edit disable-fields-set"
                    onChange={(e) => handleInputChange(e, setRefund)}
                    required={true}
                    onBlur={handleBlurRefund}
                    prefix={() => (
                      <>
                        <InputPrefix
                          style={{ paddingLeft: "20px", paddingRight: "20px" }}
                        >
                          ₹
                        </InputPrefix>
                      </>
                    )}
                    // style={{ width: "80%" }}
                  />
                  {refundvalidation && <Error>{refundvalidation}</Error>}
                </div>
              </div>
              <div className="button-group">
                {views === undefined ? (
                  <div>
                    <Button
                      type="submit"
                      className="button-view"
                      onClick={updateBookingDetails}
                    >
                      UPDATE
                    </Button>
                  </div>
                ) : (
                  <div> </div>
                )}
                <Button
                  type="submit"
                  className="common-button-view"
                  onClick={backScreen}
                >
                  CANCEL
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
