import Api from "../Api";
import config from "../Config";
import axios from "axios";

export const samplePostAPI = (params) => {
  return Api.post("/example/usrs", params);
};

export const sampleGetAPI = () => {
  const header = {
    accept: "application/json;odata.metadata=minimal;odata.streaming=true",
  };
  return Api.get("/odata/SpaceTypes", { headers: header });
};

export const apiAuthenticateLogin = (userName, password) => {
  const headers = {
    accept: "application/json;odata.metadata=minimal;odata.streaming=true",
    // accept: "*/*",
    // "content-type": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  };
  const instance = axios.create();
  // let url = "https://uat-cw-api.equadriga.in/"; //config.baseURL;
  let url = "https://preprod-api.workfromhaven.in/"; //config.baseURL;

  return instance.post(
    url + "login?userName=" + userName + "&password=" + password
  );
  // return instance.post(url + "login", params);
};
