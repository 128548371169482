import React, { useEffect, useState } from "react";
import { Grid, GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import {
  chevronDownIcon,
  eyeIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const BookingManagement = () => {
  const defaultBookingTypeValue = { SpaceType: "Select Booking Type" };

  // Dani code
  const [mainData, setMainData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState("");
  const [newToDate, setToDate] = useState<Date | null>();
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [dataResult, setDataResult] = useState(
    process(filteredData, dataState)
  );
  const [bookingTypeList, setBookinTypeList] = useState([]);

  const [data, setData] = useState(filteredData);

  // Reset filter values
  const resetSearch = () => {
    setFilterValue("");
    const dataValue: any = searchData;
    for (var i = 0; i < dataValue.length; i++) {
      dataValue[i]["UserId"] = i + 1;
    }
    setFilteredData(dataValue);
  };

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };
  //

  // Filter search
  const onFilterChange = (ev: any) => {
    let value = ev.value;
    setFilterValue(ev.value);
    var newData: any = [];

    if (value === "") {
      newData = searchData;
    } else {
      newData = searchData.filter((item: any) => {
        return (
          item.CustomerName === null ||
          item.CustomerName.toLowerCase().includes(value.toLowerCase()) ||
          item.BookingId.toLowerCase().includes(value.toLowerCase()) ||
          item.ContactNo.toString().includes(value.toString())
        );
      });
    }
    for (var i = 0; i < newData.length; i++) {
      newData[i]["UserId"] = i + 1;
    }
    setFilteredData(newData);
    let clearedPagerDataState = { ...dataState, take: 5, skip: 0 };
    let processedData = process(newData, clearedPagerDataState);
    setDataResult(processedData);
    setDataState(clearedPagerDataState);
    setData(newData);
  };

  const [bookingType, setBookingType] = useState(defaultBookingTypeValue);

  // Date  filter
  const onFilterChangetodate = (ev: any) => {
    // Create a Date object from the string
    const date = new Date(ev.value);

    // Extract the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-based index
    const day = String(date.getDate()).padStart(2, "0");

    // Format as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    setSearchFromDate(formattedDate);
    setToDate(ev.value);
  };

  const [editItems, setEditItem] = React.useState({});

  // Edit, View & Delete column booking list
  const MyEditCommandCell = (props: EditCommandCellProps) => (
    <EditCommandCell {...props} enterEdit={props.enterEdit} />
  );

  const EditCommandCell = (props: EditCommandCellProps) => {
    return (
      <td>
        <Button
          className="edit-color"
          type="button"
          svgIcon={pencilIcon}
          onClick={() => props.enterEdit(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterEdit = (item: any) => {
    for (let d = 0; d < originalData.length; d++) {
      if (item.Id === originalData[d]["Id"]) {
        getBookingData(originalData[d]["Id"], undefined);
      }
    }
  };

  const getBookingData = async (id: any, view: any) => {
    try {
      const response = await axios.get(
        `https://preprod-api.workfromhaven.in/odata/Bookings(${id})`
      );
      navigate("/management/editbooking", {
        state: { infoId: response.data, view },
      });
    } catch (err) {}
  };

  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell {...props} enterView={props.enterView} />
  );

  const ViewCommandCell = (props: ViewCommandCellProps) => {
    return (
      <td>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => props.enterView(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterView = (item: any) => {
    for (let d = 0; d < originalData.length; d++) {
      if (item.Id === originalData[d]["Id"]) {
        getBookingData(originalData[d]["Id"], true);
        // navigate("/management/editbooking", {
        //   state: { infoId: originalData[d] },
        // });
        // setEditItem(originalData[d]);
      }
    }
    // navigate("/management/editbooking", {
    //   state: { infoId: item, view: true },
    // });
    setEditItem(item);
  };

  const MyDeleteCommandCell = (props: DeleteCommandCellProps) => (
    <DeleteCommandCell {...props} enterDelete={props.enterDelete} />
  );

  const DeleteCommandCell = (props: DeleteCommandCellProps) => {
    return (
      <td>
        <Button
          className="delete-color"
          type="button"
          svgIcon={trashIcon}
        ></Button>
      </td>
    );
  };

  const enterDelete = (item: any) => {
    console.log("Pressed the delete button");
    console.log(item.Id);
    DeleteApi(item.Id);
    // navigate("/management/editbooking", { state: { infoId: item } });
    // setEditItem(item);
  };
  //

  // Clear data
  const clearInput = () => {
    setToDate(null);
    setBookingType(defaultBookingTypeValue);
    setFilteredData(mainData);
    setSearchBooking("");
    setSearchFromDate("");
  };

  const onHandleSearch = () => {
    var bookingFilterURL = ``;
    if (searchBooking === "" && SearchFromDate === "") {
    } else if (
      searchBooking !== "" &&
      searchBooking !== undefined &&
      SearchFromDate === ""
    ) {
      bookingFilterURL = `https://preprod-api.workfromhaven.in/odata/Bookings?$expand=Space&$filter=SpaceId%20eq%20${searchBooking}&orderby=Id desc`;
      BookingExpandWithFilterAPi(bookingFilterURL);
    } else if (
      (searchBooking === "" || searchBooking === undefined) &&
      SearchFromDate !== ""
    ) {
      bookingFilterURL = `
https://preprod-api.workfromhaven.in/odata/Bookings?$expand=Space
&$filter=BookingDatetime%20eq%20${SearchFromDate}&orderby=Id desc`;
      BookingExpandWithFilterAPi(bookingFilterURL);
    } else if (
      searchBooking !== "" &&
      searchBooking !== undefined &&
      SearchFromDate !== ""
    ) {
      bookingFilterURL = `
https://preprod-api.workfromhaven.in/odata/Bookings?$expand=Space
&$filter=SpaceId%20eq%20${searchBooking}%20and%20BookingDatetime%20eq%20${SearchFromDate}&orderby=Id desc`;
      BookingExpandWithFilterAPi(bookingFilterURL);
    }
  };

  const [searchBooking, setSearchBooking] = useState("");
  const [SearchFromDate, setSearchFromDate] = useState("");

  const handleChangeBookingType = (e: any) => {
    setSearchBooking(e.value.Id);
    setBookingType(e.value);
  };

  // Breadcrums
  const breadCrumbsData = [
    {
      id: "1",
      text: "Booking Management",
      route: "",
    },
    {
      id: "2",
      text: "List of Booking",
      route: "",
    },
  ];

  // Convert date format
  const formatDate = (dateString: any) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    // List of booking type
    const SpacesApi = async () => {
      try {
        const response = await axios.get(
          `https://preprod-api.workfromhaven.in/odata/Spaces`
        );
        setBookinTypeList(response.data.value);
        BookingExpandAPi();
      } catch (err) {}
    };

    SpacesApi();
  }, []);

  // List of booking with expand list
  const BookingExpandAPi = async () => {
    try {
      const response = await axios.get(
        `https://preprod-api.workfromhaven.in/odata/Bookings?$expand=Space&orderby=Id desc`
      );
      var content = response.data.value;
      setOriginalData(response.data.value);

      const contentLen = content.length;
      for (let i = 0; i < contentLen; i++) {
        const bookingDaeandtime =
          content[i]["BookingDatetime"] === null
            ? ""
            : content[i]["BookingDatetime"];

        // // Split at 'T' to separate date and time
        const [date1, timeWithZone1] =
          bookingDaeandtime !== undefined ? bookingDaeandtime.split("T") : "";

        content[i]["BookingDatetime"] = formatDate(date1);
        content[i]["UserId"] = i + 1;
      }
      setMainData(content);
      setFilteredData(content);
      setSearchData(content);
    } catch (err) {}
  };

  // List of booking expand list with filter
  const BookingExpandWithFilterAPi = async (filterUrl: any) => {
    try {
      const response = await axios.get(filterUrl);
      var content = response.data.value;
      const contentLen = content.length;
      for (let i = 0; i < contentLen; i++) {
        const bookingDaeandtime =
          content[i]["BookingDatetime"] === null
            ? ""
            : content[i]["BookingDatetime"];

        // // Split at 'T' to separate date and time
        const [date1, timeWithZone1] =
          bookingDaeandtime !== undefined ? bookingDaeandtime.split("T") : "";

        content[i]["BookingDatetime"] = formatDate(date1);
        content[i]["UserId"] = i + 1;
      }
      setFilteredData(content);
      setSearchData(content);
    } catch (err) {}
  };

  const DeleteApi = async (id: number) => {
    try {
      const response = await axios.get(
        `https://preprod-api.workfromhaven.in/odata/DeleteBooking?key=${id}`
      );
      console.log(response);
    } catch (err) {}
  };

  return (
    <div className="overall-view ">
      <div>
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      <div className="common-margin">
        <Label className="list-heading">Filter</Label>
        <div className=" filter-view ">
          <div className="rowset">
            <div className="columnset">
              <Label className="common-label-size">Booking Type</Label>
              <DropDownList
                svgIcon={chevronDownIcon}
                name="bookingType"
                className="book-dropdown"
                data={bookingTypeList}
                textField="SpaceType"
                value={bookingType}
                onChange={(e) => handleChangeBookingType(e)}
              />
            </div>
            <div className="columnset-datepicker">
              <Label className="common-label-size">Booking Date</Label>
              <DatePicker
                name="toDate"
                className="book-dropdown"
                onChange={onFilterChangetodate}
                value={newToDate}
                format={"dd/MM/yyyy"}
                placeholder="DD/MM/YYYY"
              />
            </div>
            <div className="columnset-end">
              <Label className="common-label-size"></Label>
              <Button
                className="common-button-view search-color"
                onClick={onHandleSearch}
              >
                Search
              </Button>
            </div>
            <div className="columnset-end">
              <Label className="common-label-size"></Label>
              <Button
                className="common-button-view clear-color"
                onClick={clearInput}
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-container"></div>
      <div className="common-margin view-margin">
        <div className="table-title-view">
          <div className="large-custom-input-container">
            <Label className="list-heading">Booking List</Label>
            <TextBox
              className="textBoxcustom"
              placeholder="Search by Customer Name / Booking ID / Mobile Number"
              name="roomcost"
              value={filterValue}
              onChange={onFilterChange}
              suffix={() => (
                <>
                  <span className="input-icon">
                    {filterValue ? (
                      <Button icon="close" onClick={resetSearch} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
          </div>
        </div>
        <div>
          {/* {filteredData.length === 0 && loadingPanel} */}
          <Grid
            data={process(filteredData, dataState)}
            pageable={getPageableSettings()}
            skip={dataState.skip}
            take={dataState.take}
            total={filteredData.length}
            onPageChange={(e) => setDataState(e.page)}
            onDataStateChange={handlePageChange}
          >
            <GridColumn
              field="UserId"
              title="S.No"
              width={"100px"}
              format="{0:d}"
              className="list-sno"
            />
            <GridColumn
              field="BookingId"
              title="Booking ID"
              filter="numeric"
              width={"200px"}
            />
            <GridColumn
              field="Space.SpaceType"
              title="Space Type"
              filter="text"
            />
            <GridColumn
              field="CustomerName"
              title="Customer Name"
              className="customer-name-styles"
            />
            <GridColumn field="ContactNo" title="Customer Mobile" />
            <GridColumn field="BookingDatetime" title="Booking Date" />
            <GridColumn
              field="sno"
              title="Action"
              className="Data"
              width={"240px"}
              cell={(props) => (
                <div>
                  <MyViewCommandCell {...props} enterView={enterView} />
                  <MyEditCommandCell {...props} enterEdit={enterEdit} />
                  <MyDeleteCommandCell {...props} enterDelete={enterDelete} />
                </div>
              )}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: any) => void;
}
interface ViewCommandCellProps extends GridCellProps {
  enterView: (item: any) => void;
}
interface DeleteCommandCellProps extends GridCellProps {
  enterDelete: (item: any) => void;
}
